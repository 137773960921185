@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 3800px) and (max-width: 3840px){
  .hmcc-dd-section .filter-dropbtn-parent {
    top: 0vh !important;

    border-radius: 0.5em;
  }
  .hmcc-dd-section .filter-dropbtn-parent .map-filter {
    /* changes */
    /* min-width: 11.3rem !important; */
    min-width: 14.8rem !important;
    position: relative;
    left: 34px !important;
  }
  /* .modal-90w {
    max-width: 31.5% !important;
  } */
}

@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 3800px) and (max-width: 3840px) {
  .business_modal-holder-b9 .modal-lg {
    max-width: 25vw;
  }
  .modal-backdrop.show {
    opacity: 0;
    z-index: -1;
  }
  .dashboard-modal-header {
    background-color: #0058ff;
  }
  .announcements-height {
    height: 5vh;
    margin-top: 12px;
  }
  .data-sections2 {
    padding: 0px;
  }
  .operations-height {
    height: 30.875rem;
    zoom: 0.7;
  }
  .live-notifications-height {
    height: 17.6vh;
    zoom: 0.8;
  }
  .dropdown-content {
    max-height: 20vh !important;
  }
  .world-map {
    padding-top: 4vh;
  }
}

@media screen and (min-height: 1000px) and (max-height: 1080px) and (min-width: 3800px) and (max-width: 3840px) {
  .hmcc-dd-section .filter-dropbtn-parent {
    /* top: 0vh !important; */
    top: 4vh !important;
    border-radius: 0.5em;
    height: auto;
    min-height: max-content;
  }
  .hmcc-dd-options {
    left: 1.6em;
    border-radius: 1rem;
    width: 89%;
  }
  .hmcc-client-selected {
    min-width: 90%;
    margin-top: 11px;
  }
  .selected-client-text {
    font-family: "GraphikMedium";
    font-size: 20px;
    position: relative;
    top: 0.8rem;
  }
  /* .hmcc-dd-section .filter-dropbtn-parent .map-filter {
   
    min-width: 12.8rem !important;
    position: relative;
    left: 19px !important;
  } */
  .hmcc-dd-section .hmcc-drop-btn.map-filter {
    border-radius: 1rem;
    /*changes*/
    margin: 0.8rem 0.8rem;
  }
  /*changesss*/
  .hmcc-drop-btn.map-filter img {
    position: absolute;
    right: 2rem;
    top: 0.8rem;
  }
  .hmcc-dd-section .filter-dropbtn-parent {
    max-height: 50vh;
  }
  .ClientListInner {
    height: 20vh;
  }
  .selectClientHolderDivB9 {
    /* top: 5.5vh;
    left: 0vh; */
    /*changes*/
    top: 6vh;
    left: 5vh;
    width: 14rem;
    min-height: 0px;
    max-height: 0px;
  }
  .selectClientHolderDivB9{
    border-radius: 1rem;
    /* min-height: 26rem; */
    max-height: 80vh;
    width: 14.8rem;
    position: absolute;
    left: 2.8rem;
    /* top:9vh; */
    z-index: 10000;
    text-align: left;
    padding: 1rem 0px;
  }

  .selectClientHolderDivB9 {
    background: rgba(2, 20, 70, 0.9);
    border: 1px solid rgb(27, 94, 246);
    color: #fff;
  }
  .selectClientHolderDivB9 a {
    color: rgb(145, 158, 171);
  }
  .selectClientHolderDivB9 a:hover {
    background: rgba(105, 151, 255, 0.2);
    color: #fff;
  }

  .selectClientHolderDivB9 a{
    cursor: pointer;
  }
  .selectClientHolderDivB9 h5{ 
    padding-left: 1.5rem;
    font-size: .7rem;
    margin-bottom: 0rem;
    font-family: 'GraphikRegular';
  }
  .selectClientHolderDivB9 img{
    height: 2rem;
    }
    .selectClientHolderDivB9 .media-body{
    font-size: 1rem;
    padding-top: .4rem;
    }
  .hmcc-dd-options .dropdown-content {
    width: 12rem;
  }
  .mb-2,
  .my-2 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .react-confirm-alert-overlay {
    z-index: 1051;
  }
  .app-frame-container {
    height: 80vh;
  }
  .app-store-iframe {
    min-height: 118vh;
  }
  .modal-footer {
    border-top: 0;
  }
  .react-confirm-alert {
    position: fixed;
    left: 12%;
    top: 30%;
  }
  .container {
    max-width: 1400px;
  }
   .subtab-height{
    height: 96vh;
    overflow-y: auto;
  }
  .iframe-modal-body .db-container {
    max-height: 107vh;
    height: auto;
  }
  .iframe-modal-body{
    max-height: 83vh;
    height: auto;
  }
  .db-container {
    max-height: 120vh;
  }
  .subtab-height1{
    overflow-y: auto;
  }
  .ioj-filter-buttons{
    top: 1rem;
  }
  .info-text{
    color: rgb(126, 195, 68) !important;
  }
  .power-bi-frame {
  height: 100vh !important;
  }
  .data-visualisation-container {
  min-height: 76vh;
  margin-top: 2vh;
}
.maximizedAppStoreB9 .data-visualisation-container {
  min-height: 92vh;
}		
.data-visualisation-container .title-section{
  zoom:100%
}
.data-visualisation-container .graph-view-section {
  margin-top: 0;
  zoom: 100%;
}
.data-visualisation-container #wheel-chart-box{
margin-top: -0;
}
.modal-custom-BOI-container .modal-dialog{
  position: relative;
  left: -33%;
  top: 15vh;
}
  .synopsbackLogo {
    margin-top: 12rem;
    height: 55vh;
    margin-left: 6rem;
}
.synopsHeading{
  font-size: 48px;
}
.main-hmcc-footer-b9 {
  position: fixed;
  bottom: 2vh;
  margin-left: -3.4rem;
  width: 74.25rem;
}
.anchor-b9 {
  top:47px;
}
.anchor-b9 img{
  height: 2.5rem !important;
}
.data-sections2{
  width: 96% !important;
}
.b9-3rd-section{
  margin-top: 0;
}
.hmcc-dd-section .filter-dropbtn-parent .map-filter {
  min-width: 12.8rem!important;
}
.hmcc-dd-section .filter-dropbtn-parent{
  min-width: 77.25em !important;
}
.center-bottonsB9 {
  width: 108%;
}
.Leftmodal {
  left: 1.25rem;
  top:0.45rem;
}
.Leftmodal .modal-content {
  height: 92.5vh;
}
.LeftmodalTableau {
  position: absolute !important;
  top: 1rem;
  left: 3.6rem;
  transform: translate(-50%, -50%);
}
.Leftmodal .tab-pane .new-subTablist .newTab-text{
  margin: 0 9px 0 0;
  font-size: 1.2rem !important;
}
.worldMapSvg-b9{
  width: 120%;
  left: 6%;
}
.b9-bg-top {
  top: 2.25rem;
  left: 0.15rem;
}
.b9ddc5maxwidth{
max-width: 30vw;
}
/* Analytic bot styles */
.chatIcon {
  position: absolute;
  background-color: #4148d6;
  bottom: 0.7rem;
  right: -1.0rem;
  height: 41px;
  width: 41px;
}
.chatModalDialog{
  right: 67vw;
}
.expandedChartModalContent {
  height: 500px !important;
  width: 30% !important;
}
.chatModalDialogMax .chatModalContent{
  width: 30vw;
}
}
