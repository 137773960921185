body {
  margin: 0;
  font-family: "GraphikRegular", "GraphikRegularIE", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "GraphikRegular", "GraphikRegularIE", Arial, sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px grey !important;
  border-radius: 10px !important;
  background: #0242c6 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #000663 !important;
}
.main-bg {
  background-color: #00185f;
}
.main-bg-g {
  background: linear-gradient(to right, #48abff, #0058ff);
}
.hmcc-nav-text {
  color: #fff;
}
.hmcc-nav-text:hover {
  color: #fff;
  border-bottom: 8px #6ef9ff solid;
}
.hmcc-nav-text-active {
  border-bottom: 8px #6ef9ff solid;
}
.main-section {
  background: #164ac2; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #164ac2 0%,
    #021f6f 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #164ac2 0%,
    #021f6f 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #164ac2 0%,
    #021f6f 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#164ac2', endColorstr='#021f6f',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.HM-Main-BG {
  background: #1544b1; /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #1544b1 0%,
    #001858 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #1544b1 0%,
    #001858 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    #1544b1 0%,
    #001858 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1544b1', endColorstr='#001858',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.main-section2 {
  background: linear-gradient(#154ac1, #021d6e);
}
.world-map .jvectormap-container {
  background-color: transparent !important;
}
.data-container {
  background: #0058ff !important;
}
.data-container2 {
  background: #0058ff !important;
}
.highlight .tab-button {
  background: linear-gradient(to right, #154ac1, #021d6e) !important;
  border: 0.13rem solid #0de2bb !important;
}
.data-container .heading {
  color: #fff;
}
.couting-text {
  color: black !important;
}
.tab-button-m {
  background: linear-gradient(
    90deg,
    rgb(42, 120, 229) 0%,
    rgb(17, 75, 202) 100%
  );
}
.tab-button-m:hover {
  background: linear-gradient(90deg, rgb(16, 63, 174) 0%, rgb(2, 29, 110) 100%);
}
.tab-button-m-active {
  background: linear-gradient(90deg, rgb(16, 63, 174) 0%, rgb(2, 29, 110) 100%);
}
.tab-button {
  border: 0.1rem solid #fff;
  background: transparent;
  box-shadow: #01176f;
  color: #fff;
}
.bottom-tab {
  color: #fff;
  background: #48abff;
  background: linear-gradient(to right, #58aaf8, #226eff) !important;
}
.bottom-tab:hover {
  background: linear-gradient(to right, #154ac1, #021d6e) !important;
}
.bottom-tab-active {
  background: linear-gradient(to right, #154ac1, #021d6e) !important;
}
.dot {
  background-color: #fff;
}
.tab-pane .newTab-text:after {
  background: #0de2bb;
}
.tab-pane .new-subTablist .newTab-text:hover::after,
.tab-pane .new-subTablist .newTab-text-active.newTab-text::after {
  border-bottom: 3px solid #0de2bb;
}
.tab-pane .new-subTablist .newTab-text:hover,
.tab-pane .new-subTablist .newTab-text-active {
  color: #fff;
}
.hmcc-logo-text::after {
  background: #fff;
}
.selectClientHolderDiv {
  background: rgba(2, 20, 70, 0.9);
  border: 1px solid rgb(27, 94, 246);
  color: #fff;
}
.selectClientHolderDiv a {
  color: rgb(145, 158, 171);
}
.selectClientHolderDiv a:hover {
  background: rgba(105, 151, 255, 0.2);
  color: #fff;
}
.clearSearchCTA {
  background-color: transparent !important;
  color: rgb(27, 94, 246) !important;
}
.default-btn-bg {
  background: linear-gradient(to right, #41a2fe, #114bca) !important;
  border: 1px solid #ffffff !important;
}
.default-btn-bg:hover {
  background: linear-gradient(to right, #154ac1, #021d6e) !important;
}
.anchor {
  color: white;
}
.anchor:hover {
  color: black;
}
.app-store-modal .modal-content {
  background: rgb(2, 66, 198);
}
.switch3 {
  border: 2px solid #fff;
  background: linear-gradient(to top, #b548ff, #fc87f9);
}
.tab-pane .new-subTablist .newTab-text {
  color: #fff;
}
.modal-90w .modal-content {
  background: #0058ff;
}
.checkbox-dropdown-items {
  background-color: rgba(246, 246, 246, 0.505);
}
#myInput,
#myInput1,
#myInput2,
#myInput3,
#myInput4 {
  border: 2px solid #fff !important;
}
.hmcc-dd-options .hmcc-searchbox #myInput,
.hmcc-dd-options .hmcc-searchbox #myInput1,
.hmcc-dd-options .hmcc-searchbox #myInput2,
.hmcc-dd-options .hmcc-searchbox #myInput3,
.hmcc-dd-options .hmcc-searchbox #myInput4,
.HMCCtxtFld {
  border: 0.0625rem solid rgb(27, 94, 246) !important;
}
.hmcc-dd-options-footer {
  background: rgba(105, 151, 255, 0.2);
}
.MuiTabScrollButton-root {
  background: #3aa2ff !important;
  color: white !important;
}
.data-sections2 {
  background: #0058ff !important;
}
.widget-no-result-overlay {
  background: rgb(0, 0, 68) !important;
}
.db-container {
  background-color: #0058ff;
}
.app-store-parent {
  color: white;
}
.app-store-modal {
  background: rgb(2, 66, 198);
}
.app-store-modal-body {
  background-color: rgb(2, 66, 198);
  color: white;
}
.appstore-modal-btn {
  background: rgb(27, 94, 246);
  color: #fff;
}
.appstore-apply-btn {
  background: #0058ff;
  color: #fff;
}
.app-card {
  background: #0058ff;
}
.app-card-plus-btn {
  background: #0058ff;
  color: #fff;
  border: 1.5px solid #ffffff;
}
.app-card-minus-btn {
  background: #0058ff;
  color: #fff;
  border: 1.5px solid #ffffff;
}
.minus-btn {
  color: red;
}
.plus-btn {
  color: #07fc03;
}
.app-frame-container {
  background: #0058ff;
}
.dot-dot-icon {
  color: white;
}
.BO-modal {
  background: #0058ff;
}
.business_modal-holder .modal-content {
  background-color: transparent !important;
}
.app-action-btns .btn-cancel {
  border: 0.0938rem solid rgb(27, 94, 246);
  color: rgb(255, 255, 255);
}
.app-action-btns .btn-apply {
  border: 0.0938rem solid rgb(27, 94, 246);
  color: rgb(255, 255, 255);
}
.app-action-btns .btn-cancel:hover {
  background: rgb(27, 94, 246);
}
.app-action-btns .btn-apply:hover {
  background: rgb(27, 94, 246);
}
.appstore-header .header-title {
  color: rgb(255, 255, 255);
}
.card-appstore-btns .btn-remove {
  border: 0.0625rem solid rgb(255, 255, 255);
  color: rgb(234, 234, 234);
}
.appstore-currentApps h6,
.appstore-availableApps h6 {
  color: rgb(255, 255, 255);
}
.card-appstore-title {
  color: rgb(255, 255, 255);
}
.card-appstore-description {
  color: rgb(225, 225, 225);
}
.card-appstore {
  background: rgb(2, 66, 198);
}
.btn-add {
  color: rgb(234, 234, 234);
  border: 0.0625rem solid rgb(255, 255, 255);
}
.AS-no-apps {
  color: white;
}
.custom-ui {
  color: white;
  background: rgb(2, 66, 198);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.reactToolTipClient {
  background-color: white !important;
}
.hide-top-bar {
  background: transparent !important;
}
.info-text {
  color: #c9ff6b !important;
}
.hmcc-button {
  border: 2px solid #fff;
  background: linear-gradient(to right, #48abff, #0058ff);
  color: #fff;
}
.inner-date {
  background: #01166c;
}
.data-box,
.data-box2 {
  background: linear-gradient(to bottom, #218ffe, #0434c8);
  border: solid #fff 2px;
}
.info-icon {
  box-shadow: 0 2px 5px #484848;
}
.infomation-icon {
  background: #48abff;
  box-shadow: inset 2px 2px 3px #2b2b2b;
}
.hmcc-button option {
  color: #000;
}
.transparent-bg-top {
  background: rgba(105, 151, 255, 0.2);
}
.HMFilter-btn-holder {
  background: rgba(105, 151, 255, 0.2);
}
.hmcc-dd-section .hmcc-drop-btn.map-filter {
  background: rgba(255, 255, 255, 0.15) !important;
  border: 0.0625rem solid rgb(160, 172, 183) !important;
  color: rgb(145, 158, 171);
}
.hmcc-new-drop-btn .applyBtn {
  border: 0.09375rem solid rgb(27, 94, 246) !important;
  background: transparent;

  /* background: rgb(27, 94, 246); */
}
.hmcc-new-drop-btn .applyBtn:hover {
  background: rgb(27, 94, 246);
}
.hmcc-new-drop-btn .clearBtn {
  border: 0.09375rem solid rgb(27, 94, 246);
  cursor: pointer;
}
.hmcc-new-drop-btn .clearBtn:hover {
  border: 0.09375rem solid rgb(27, 94, 246);
}
.hmcc-new-drop-btn .clearBtn:hover {
  background: rgb(27, 94, 246);
}
.hmcc-dd-options {
  background: rgb(2, 20, 70);
  border: 0.0625rem solid rgb(27, 94, 246);
  box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.15);
}
.hmcc-dd-options .checkbox-dropdown-items {
  background: transparent;
  color: rgb(160, 172, 183);
}
.hmcc-dd-options-footer .clearBtn {
  border: 0.09375rem solid rgb(27, 94, 246);
  background: transparent;
  cursor: pointer;
}
.hmcc-dd-options-footer .applyBtn {
  border: 0.09375rem solid rgb(27, 94, 246) !important;
  background: transparent;
  cursor: pointer;
}
.hmcc-dd-options-footer .clearBtn:hover {
  border: 0.09375rem solid rgb(27, 94, 246);
  background: rgb(27, 94, 246);
}
.hmcc-dd-options-footer .applyBtn:hover {
  background: rgb(27, 94, 246);
}
.hmcc-drop-selectedItems {
  background: #1b5ef6;
  color: #fff;
}
.map-filter-appliedFilters {
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid rgb(27, 94, 246);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.FilterType {
  color: rgb(255, 255, 255);
}
.hmcc-dd-options .dropdown-content label.active {
  color: #fff;
}
.hmcc-dd-section h6 {
  color: rgb(255, 255, 255);
}
.hmcc-dd-section .filter-dropbtn-parent {
  background: rgba(2, 20, 70, 0.89);
}
.row-divider {
  border-top: 1px solid rgb(58, 162, 255);
}
.hmcc-dd-options .checkbox-dropdown-items,
.HMCCtxtFld {
  background: transparent;
  color: rgb(160, 172, 183);
}
.HMCCtxtFld {
  background: transparent;
  background: url(Images/search-grey-icon.png) 10px 5px scroll no-repeat;
}
.hmcc-dd-section .checkbox-dropdown-items:hover,
.HMCCtxtFld:hover {
  background: rgba(105, 151, 255, 0.2);
}
.hmcc-text {
  color: #fff;
}
.transparent-bg-bottom {
  background: rgba(105, 151, 255, 0.2);
}
.toggle {
  background-color: #3959ad;
}
.check:checked ~ .track {
  box-shadow: inset 0 0 0 20px #021d6e;
}

.switch {
  background: #9efff4;
}
/*changes*/
.switch-B9 {
  background: linear-gradient(
    -180deg,
    rgb(255, 230, 87) 0%,
    rgb(255, 200, 44) 100%
  );
}

.couting-text {
  color: #fff;
}
.track {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.05);
}
.track p {
  color: #fff;
}

.switch2 {
  background: #c9ff6b;
}
.hmcc-drop-btn {
  background: linear-gradient(
    90deg,
    rgb(29, 123, 213) 0%,
    rgb(17, 75, 202) 100%
  );
  color: #fff;
  border: 1px solid #fff;
}
.hmcc-drop-btn:hover {
  background: linear-gradient(to right, #103ead, #02136a);
  border: 1px solid #0de2bb;
}
.hmcc-drop-btn-active {
  background-image: linear-gradient(to right, #103ead, #02136a);
  border: 1px solid #0de2bb;
}
.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}
#myInput {
  border-bottom: 1px solid #ddd;
}
#myInput:focus {
  outline: 3px solid #ddd;
}
.dropdown-content label {
  color: black;
}
.hmcc-dd-options .dropdown-content label {
  color: rgb(160, 172, 183);
}
.checkbox-dropdown-items {
  background-color: #f6f6f681;
}
.checkbox-dropdown-items:hover {
  background-color: #dcdcdc;
}
.hmcc-dd-options .checkbox-dropdown-items {
  color: rgb(160, 172, 183);
}
.hmcc-dd-section .checkbox-dropdown-items:hover {
  background: rgba(105, 151, 255, 0.2);
}
.buttons {
  color: white;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #48abff;
}
::-webkit-scrollbar-thumb:hover {
  background: #48abff;
}
.data-container2 {
  background: #1b5ef6;
}

.hidebutton {
  background: rgba(255, 255, 255, 0.29);
}
.mapMarkers:hover {
  stroke: black;
}
.profileCntnrDiv {
  background: rgb(2, 23, 77);
  color: rgb(145, 158, 171);
}
.profile-img {
  background: rgb(36, 50, 97);
}
@media only screen and (max-width: 991px) {
  .box1,
  .box2 {
    background: rgb(173, 173, 173);
  }
  .box1 small {
    color: rgb(173, 173, 173);
  }
  .box1-active {
    background: linear-gradient(to top, #b548ff, #fc87f9);
  }
  .box2-active {
    background: linear-gradient(to top, #32a0c5, #2afadd);
  }
  .box1-active small {
    color: #fc87f9;
  }
  .box2-active small {
    color: #2afadd;
  }
  .hmcc-footer2 h5 {
    background: #01176f;
    color: #fff;
  }
  .box1 small,
  .box2 small {
    background: #fff;
  }
  .box1 h4,
  .box2 h4 {
    color: #fff;
  }
}
@media only screen and (max-width: 768px) {
  .hmcc-drop-btn-close {
    background: linear-gradient(to right, #48abff, #0058ff);
    color: #fff;
    border: 2px solid #fff;
  }
  .inner-date {
    border-bottom: 2px solid #6ef9ff;
  }
  .bottom-tab {
    border-bottom: 3px solid transparent;
  }
  .mobile-view {
    border-right: 5px solid #fff;
    background: linear-gradient(to top, #01176f, #0149d0);
  }
  .hmcc-flex-child {
    border: 1px solid #fff;
  }
}
@media only screen and (max-width: 576px) {
  .bottom-tab:hover {
    border-bottom: 3px solid #fff;
  }
  .bottom-tab-active {
    border-bottom: 3px solid #fff;
  }
}

.no-results {
  justify-content: center;
}
/* to make checkbox and label in single row.*/
.custom-checkbox__label{
  display: flex !important;
  } 
/* to hide check marks. */
span.custom-checkbox__icon > svg.app-icon__svg{
  display: none !important;
  }   
